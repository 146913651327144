import React from 'react'
import './Portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio5.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio2.jpg'
import IMG6 from '../../assets/portfolio6.jpg'

const data = [
  {
    id : 1,
    img : IMG1,
    title : 'Crypto Currency Dashboard & Financial Visualization',
    github : 'https://github.com',
    demo : 'https://dribbble.com/shots/16673715-Crypto-currency-dashboard-and-financial-data-visualization'
  },
  {
    id : 2,
    img : IMG2,
    title : 'Figma dashboard UI kit for data design web apps',
    github : 'https://github.com',
    demo : 'https://dribbble.com/shots/19314442-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps'
  },
  {
    id : 3,
    img : IMG3,
    title : 'Figma dashboard UI kit for data design web apps',
    github : 'https://github.com',
    demo : 'https://dribbble.com/shots/17290917-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps'
  },
  {
    id : 4,
    img : IMG4,
    title : 'Mainitaining tasks and tracking progress',
    github : 'https://github.com',
    demo : 'https://dribbble.com/shots/16955822-Maintaining-tasks-and-tracking-progress'
  },
  {
    id : 5,
    img : IMG5,
    title : 'Charts templates & infographics in Figma',
    github : 'https://github.com',
    demo : 'https://dribbble.com/shots/16580766-Orion-UI-kit-Charts-templates-infographics-in-Figma'
  },
  {
    id : 6,
    img : IMG6,
    title : 'Figma dashboard UI kit for data design web apps',
    github : 'https://github.com',
    demo : 'https://dribbble.com/shots/18936884-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps'
  },
]






const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>PortFolio</h2>

      <div className="container portfolio_container">
        {
          data.map(({id, img, title, github, demo}) => {
            return(
              <article key={id} className="portfolio_items">
                <div className="portfolio_item_image">
                  <img src={img} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio_items_cta">
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }
        
        
      </div>
    </section>
  )
}

export default Portfolio