import React from "react";
import './Experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const BackEndExperience = () => {
  return (
    <div className="experience_backend">
      <h3>Backend Development</h3>
      <div className="experience_content">
      <article className="experience_details">
        <BsPatchCheckFill className="experience_details_icon" />
        <div>
          <h4>Node JS</h4>
          <small className="text-light">Experienced</small>
        </div>
      </article>
      <article className="experience_details">
        <BsPatchCheckFill className="experience_details_icon" />
        <div>
          <h4>Mango DB</h4>
          <small className="text-light">Intermediate</small>
        </div>
      </article>
      <article className="experience_details">
        <BsPatchCheckFill className="experience_details_icon" />
        <div>
          <h4>PHP</h4>
          <small className="text-light">Intermediate</small>
        </div>
      </article>
      <article className="experience_details">
        <BsPatchCheckFill className="experience_details_icon" />
        <div>
          <h4>MySql</h4>
          <small className="text-light">Experienced</small>
        </div>
      </article>
      <article className="experience_details">
        <BsPatchCheckFill className="experience_details_icon" />
        <div>
          <h4>Python</h4>
          <small className="text-light">Experienced</small>
        </div>
      </article>
      </div>
    </div>
  );
};

export default BackEndExperience;
