import React from 'react'
import { BiCheck } from 'react-icons/bi'
import "./Services.css";

const WebDevelopment = () => {
  return (
    <article className="service">
      <div className="service_head">
        <h3>Web Development</h3>
      </div>

      <ul className="service_list">
        <li>
          <BiCheck className="service_list_icon" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </li>
        <li>
          <BiCheck className="service_list_icon" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </li>
        <li>
          <BiCheck className="service_list_icon" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </li>
        <li>
          <BiCheck className="service_list_icon" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </li>
        <li>
          <BiCheck className="service_list_icon" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </li>
        <li>
          <BiCheck className="service_list_icon" />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </li>
      </ul>
    </article>
  )
}

export default WebDevelopment