import React from 'react'
import './Experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const FrontEndExperience = () => {
  return (
    <div className="experience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <BsPatchCheckFill className="experience_details_icon"/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className="experience_details_icon"/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className="experience_details_icon"/>
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className="experience_details_icon"/>
              <div>
                <h4>BootStrap</h4>
                <small className='text-light'>Experienced</small>  
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className="experience_details_icon"/>
              <div>
                <h4>TailWind</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className="experience_details_icon"/>
              <div>
                <h4>React</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
          </div>

        </div>
  )
}

export default FrontEndExperience