import React from 'react'
import ContentCreation from './ContentCreation'
import './Services.css'

import UI_UX_Design from './UI_UX_Design'
import WebDevelopment from './WebDevelopment'


const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services_container">
        <UI_UX_Design />
        <WebDevelopment />
        <ContentCreation />
      </div>
    </section>
  )
}

export default Services