import React from 'react'
import './Experience.css'
import FrontEndExperience from './FrontEndExperience'
import BackEndExperience from './BackEndExperience'


const Experience = () => {
  return (
    <section id="experience">
      <h5>What Skills I have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
        <FrontEndExperience />
        <BackEndExperience />
        
      </div>
    </section>
  )
}

export default Experience