import React from "react";
import "./Testimonials.css";
import AVTR1 from "../../assets/avatar1.jpg";
import AVTR2 from "../../assets/avatar2.jpg";
import AVTR3 from "../../assets/avatar3.jpg";
import AVTR4 from "../../assets/avatar4.jpg";

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const reviews = [
  {
    id: 1,
    avatar: AVTR1,
    name: "Ernest Achiever",
    review:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis suscipit cumque voluptatibus nesciunt deserunt esse repellendus vitae nisi facere, sequi dignissimos incidunt perferendis earum. Molestias mollitia, nemo esse officia veritatis sunt asperiores vel repudiandae necessitatibus architecto natus voluptas incidunt harum",
  },
  {
    id: 2,
    avatar: AVTR2,
    name: "Tina Snow",
    review:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis suscipit cumque voluptatibus nesciunt deserunt esse repellendus vitae nisi facere, sequi dignissimos incidunt perferendis earum. Molestias mollitia, nemo esse officia veritatis sunt asperiores vel repudiandae necessitatibus architecto natus voluptas incidunt harum",
  },
  {
    id: 3,
    avatar: AVTR3,
    name: "Ernest Kwame Despite",
    review:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis suscipit cumque voluptatibus nesciunt deserunt esse repellendus vitae nisi facere, sequi dignissimos incidunt perferendis earum. Molestias mollitia, nemo esse officia veritatis sunt asperiores vel repudiandae necessitatibus architecto natus voluptas incidunt harum",
  },
  {
    id: 4,
    avatar: AVTR4,
    name: "Nana Ama McBrown",
    review:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis suscipit cumque voluptatibus nesciunt deserunt esse repellendus vitae nisi facere, sequi dignissimos incidunt perferendis earum. Molestias mollitia, nemo esse officia veritatis sunt asperiores vel repudiandae necessitatibus architecto natus voluptas incidunt harum",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials_container"
      modules={[ Pagination ]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >
        {reviews.map(({ id, avatar, name, review }) => {
          return (
            <SwiperSlide key={id} className="testimonial">
              <div className="client_avatar">
                <img src={avatar} alt="Avatar One" />
              </div>
              <h4 className="client_name">{name}</h4>
              <small className="client_review">
                {review}
              </small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
